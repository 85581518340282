import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField} from "@material-ui/core";
import Modal from "../generales/Modal";
import qs from "qs";
import {ErrorGeneral, llamadaApiCarga} from '../funciones/ClienteAxios';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({

}));

const EditarListaDifusion = ({
                                 modalAbierto,
                                 setModalAbierto,
                                 titulo,
                                 DeviceId,
                                 difusionListSelected,
                                 UserId,
                                 history,
                                 difusionList
                             }) => {

        const classes = useStyles();

        console.log(difusionListSelected)

        const [mensaje, setMensaje] = useState("");
        const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
        const [aviso, setAviso] = useState(false);
        const [lista, setLista] = useState({
            Fecha: difusionListSelected.Fecha,
            Id: difusionListSelected.Id,
            Nom: difusionListSelected.Nom,
            Status: difusionListSelected.Status,
            UsrNom: difusionListSelected.UsrNom
        });
        const [cargando, setCargando] = useState(false);
        const [error, setError] = useState(false);
        const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

        const editarListaDifusion = () => {
            setCargando(true)
            let data = qs.stringify({
                Usuario: UserId,
                Llave: lista.Id,
                Status: lista.Status,
                Nombre: lista.Nom,
                idDispositivo: DeviceId
            });

            let url = "/difusion-edit";

            function response(response) {
                if (response) {
                    let mensaje = 'Contacto editado correctamente.'
                    toast.success(mensaje, {
                        pauseOnFocusLoss: false,
                        toastId: `nvo-perfil${6}`
                    })
                    let posicion = difusionList.findIndex((element) => element.Id === lista.Id)
                    difusionList.splice(posicion, 1, lista)

                    setModalAbierto(!setModalAbierto)
                } else {
                    let mensaje = response.mensaje
                    toast.warning(mensaje, {
                        pauseOnFocusLoss: false,
                        toastId: `nvo-perfil${6}`
                    })
                }
                setCargando(false)
            }

            llamadaApiCarga(data, url, response, ErrorGeneral, setCargando, history);

        }

        const handleNombre = (event) => {
            setLista({...lista, Nom: event.target.value})
            let dato = (difusionList.find(element => element.Nom === event.target.value.trim()))
            if (dato) {
                setError(true)
                setBotonDeshabilitado(true)
            } else {
                setError(false)
                setBotonDeshabilitado(false)
            }
        }

        const onChangeActivo = (event) => {
            event.preventDefault();
            setBotonDeshabilitado(false)
            switch (event.target.value) {
                case "0":
                    return setLista({...lista, Status: 0})
                case "1":
                    return setLista({...lista, Status: 1})
            }
        };

        return (
            <Modal hideBackdrop disableEscapeKeyDown
                   titulo={titulo}
                   modalAbierto={modalAbierto}
                   setModalAbierto={setModalAbierto}
                   guardarDatos={editarListaDifusion}
                   mensaje={mensaje}
                   tipoAdvertencia={tipoAdvertencia}
                   aviso={aviso}
                   setAviso={setAviso}
                   tamanio={"sm"}
                   btnDeshabilitado={botonDeshabilitado}
                   cargando={cargando}
                   subtitulo={''}
            >
                <form autoComplete="off">
                    <Grid container alignItems={'center'} justifyContent={'center'}>
                        <Grid item>
                            <FormControl component="fieldset">
                                {error ? <Alert severity="error">El nombre de lista de difusion ya existe.</Alert> : null}
                                <TextField label={'Nombre'} defaultValue={difusionListSelected.Nom}
                                           onChange={handleNombre}></TextField>
                                <FormLabel style={{marginTop:'1rem'}}>Estatus</FormLabel>
                                <RadioGroup row aria-label="estatus" name="Estatus" size={'small'}
                                            defaultValue={difusionListSelected.Status.toString()} onChange={onChangeActivo}>
                                    <FormControlLabel value="1" size={'small'}
                                                      control={<Radio color="primary" size={'small'}/>} label="Activo"/>
                                    <FormControlLabel value="0" size={'small'}
                                                      control={<Radio color="primary" size={'small'}/>} label="Inactivo"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </Modal>);
    }
;

export default EditarListaDifusion;
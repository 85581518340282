import React,{ useEffect, useState } from "react";
import ChatIcon from "@material-ui/icons/Chat";
//import DonutLargeIcon from "@material-ui/icons/DonutLarge";
//import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { Avatar, IconButton, TextField ,TablePagination, TableRow, TableCell} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import SidebarChats from "./SidebarChats";
import { makeStyles } from "@material-ui/core/styles";
import {ErrorGeneral,llamadaApiCarga,llamadaApiToken ,apiExtCargaToken} from '../funciones/ClienteAxios';
import qs from 'qs';
import axios from 'axios';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
const useStyles = makeStyles((theme) => ({
  sidebar: {
    flex: "0.35",
    display: "flex",
    flexDirection: "column",
    height: "90vh",
    padding:'0',
  },
  sidebarHeader: {
    display: "flex",
    justifyContent: "space-between",
    //padding: "20px",
    paddingLeft:1+'rem',
    borderRight: "1px solid lightgray",
   // marginBottom:.5+'rem',
   height: 2+'rem',
  },
  sidebarHeaderRight: {
    display: "flex",
    textAlign: "center",
    justifyContent: "space-between",
  },
  sidebarSearch: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f6f6f6",
    height: "39px",
    padding: "10px",
    border: "1px solid lightgray",
  },
  sidebarSearchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    width: "100%",
    height: "35px",
  },
  sidebarChats: {
    flex: 1,
    backgroundColor: "white",
    overflowY: "auto",
  },
  input: {
    flex: 1,
    padding: "10px",
  },
}));

const Sidebar = ({ setShowChat, setShowSidebar, viewPort ,setNumChat}) => {
  let history = useHistory();
  const classes = useStyles();
  let limitTel=300
  let limitTel2=100
  let UsuId =localStorage.getItem('UsuId') ;     
  let Dispvo =localStorage.getItem('Dispvo') ;    
  let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'    
  const source = axios.CancelToken.source();        
  const [espera,setEspera]= useState(false);
  const [rooms, setRooms] = useState([]);
  const [listUltimoMsg, setListUltimoMsg] = useState([]);
  const [infoContact, setInfoContact] = useState([]);
  const [addNewChat, setAddNewChat] = useState(true);
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  const [textFiltro,setTextFiltro]=useState("");
  let tamMax=25     
  useEffect(() => {
    const llamaApi = () => {                 
        let data = qs.stringify({
            Usuario:UsuId,            
            idDispositivo: auxDispV,
        });
        let url = "msg-wa-chat-list";
        function respuesta(auxiliar){        
          
          if (auxiliar[0].id ) 
          { //console.log(auxiliar);   
            setRooms(auxiliar)      
            numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,setListPagina)                         
            //llenaListMsg(auxiliar)
            llenaInfoContacto(auxiliar)
          }           
      }
        llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
    };
    const llenaInfoContacto = (lista) => {             
      let listCel=[]
     //console.log(lista);
      if (lista.length>limitTel) 
      {
        //let auxArr=lista.slice(0,limitTel)
        let auxArr=lista
        auxArr.forEach(element => {
          let cad=`+52 1 ${element.id.substring(3,5)} ${element.id.substring(5,9)} ${element.id.substring(9,13)}`
          listCel.push({cel:cad})
        });
      }
      else
      {
        lista.forEach(element => {
          let cad=`+52 1 ${element.id.substring(3,5)} ${element.id.substring(5,9)} ${element.id.substring(9,13)}`
          listCel.push({cel: cad})
        });
      }
      // console.log(listCel);
      let data = qs.stringify({               
          Contactos:JSON.stringify(listCel),
          idDispositivo: auxDispV,
      });
      let url = "redvargas-cel-info";
      function respuesta(auxiliar){        
        
        if (auxiliar[0].orden ) 
        { //console.log(auxiliar);  
          let arrMsg=[] 
          auxiliar.forEach((elemento)=>{
            let filtrado=lista.filter((contacto)=>contacto.id==="521"+elemento.celular+"@c.us")
            let nom=elemento.nombre
            let nomMod = nom.search(" ") !== -1 ? nom :(nom.length>25 ? nom.substring(0,22)+"...":nom)
            arrMsg.push({...filtrado[0],nameBd:nomMod,nameOriginal:nom,
              infoEstruc:`${elemento.seccNom} ${elemento.munNom}`,
              info:elemento
            })
          })
          setRooms(arrMsg)
          setListPagina(arrMsg.slice(0,25))
          //numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,setListPagina)   
          llenaListMsg(arrMsg)
          //setInfoContact(auxiliar)                           
        }           
      }      
      apiExtCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source.token)         
    };

    const llenaListMsg = (lista) => {           
      let listCel=[]
      //console.log(lista);
      if (lista.length>limitTel) 
      {
        //let auxArr=lista.slice(0,limitTel)
        let auxArr=lista
        let auxiliarErr=[]
        auxArr.forEach(element => {
          auxiliarErr.push(element)
          listCel.push({cel: element.id.substring(0,13),})
        });
        //console.log(auxArr);
        //console.log(auxiliarErr);
      }
      else
      {
        lista.forEach(element => {
          listCel.push({cel: element.id.substring(0,13)})
        });
      }
      //console.log(listCel);
      let data = qs.stringify({
          Usuario:UsuId,   
          Instancia:1,         
          Celulares:JSON.stringify(listCel),
          idDispositivo: auxDispV,
      });
      let url = "msg-chat-last";
      function respuesta(auxiliar){        
        
        if (auxiliar[0].orden ) 
        { //console.log(auxiliar);   
          let arrMsg=[]
          auxiliar.forEach((elemento)=>{
            let filtrado=lista.filter((contacto)=>contacto.id===elemento.cel+"@c.us")
            let last=elemento.msg.length>30?elemento.msg.substring(0,30)+"...":elemento.msg
            arrMsg.push({...filtrado[0],ultimoMsg:last})
          })
          //console.log(arrMsg);
          //numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,setListPagina)   
          setListPagina(arrMsg.slice(0,25))
          setRooms(arrMsg)
          //setListUltimoMsg(auxiliar)                           
        }           
      }
      llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source.token)       
    };

    llamaApi()

    return () => {
      source.cancel();
    }
  }, []);

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(rooms.slice(0,tamMax))   
    }
    else
    {
      setListPagina(rooms.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TableRow  >
      <TableCell id="celda" padding="none" >
      <TablePagination //size="small" style={{padding:0,margin:0}}
        rowsPerPageOptions={false} colSpan={3} 
        rowsPerPage={tamMax} count={rooms.length}
        page={numPagina} lang='es'
        labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count
            }
          }
          SelectProps={{
            size:"small"
          }}
          onPageChange={onChangePagina} ActionsComponent={PaginacionTab}
        />
      </TableCell>
    </TableRow>
    )      
  }
  const onChange = (event) => {
    setTextFiltro(event.target.value);
  };
 
  const busqueda=()=>{
    let respuesta=true
    if (textFiltro.trim().length>0) {
      
    
    let auxcad=textFiltro.trim().toUpperCase()
    let filt1=rooms.filter((dat)=>dat.info.seccNom.toUpperCase().search(auxcad) !== -1)
    if (respuesta&& filt1.length>0) 
    { setListPagina(filt1)      
      respuesta=false
    }
    if (respuesta) 
    {
      filt1=rooms.filter((dat)=>dat.info.munNom.toUpperCase().search(auxcad) !== -1)
      if (filt1.length>0) 
      { setListPagina(filt1)      
        respuesta=false
      } 
    }
    if (respuesta) 
    {
      filt1=rooms.filter((dat)=>dat.info.celular.toUpperCase().search(auxcad) !== -1)
      if (filt1.length>0) 
      { setListPagina(filt1)      
        respuesta=false
      } 
    }
    if (respuesta) 
    {
      filt1=rooms.filter((dat)=>dat.info.nombre.toUpperCase().search(auxcad) !== -1)
      if (filt1.length>0) 
      { setListPagina(filt1)      
        respuesta=false
      } 
    }
  }
  }

  const paginacion = !espera&&listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                                                   
  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebarHeader}>
        <Avatar /* src={"https://picsum.photos/id/200/200/300"}  */ style={{width:2+'rem',height:2+'rem'}}/>
        <h3>Instancia 1</h3>
        <div className={classes.sidebarHeaderRight}>
         {/*  <IconButton color="primary">
            <DonutLargeIcon />
          </IconButton> */}
         {/*  <IconButton color="primary">
            <ChatIcon />
          </IconButton> */}
         {/*  <IconButton color="primary">
            <MoreVertIcon />
          </IconButton> */}
        </div>
      </div>
    <div className={classes.sidebarSearch}>
        <div className={classes.sidebarSearchContainer}>          
          <TextField onChange={onChange}
            className={classes.input} id="search"
            placeholder={"Buscar chat"}
          />
          <IconButton color="primary">
            <SearchOutlined onClick={busqueda} />
          </IconButton>
        </div>
      </div>  
      {paginacion}
      <div className={classes.sidebarChats}>
        <SidebarChats addNewChat={addNewChat} />
        {listPagina.map((room) => {
          return (
            <SidebarChats setNumChat={setNumChat}
              key={room.id} id={room.id}
              contact={room}
              setShowChat={setShowChat}
              setShowSidebar={setShowSidebar}
              viewPort={viewPort}
              listUltimoMsg={listUltimoMsg}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;

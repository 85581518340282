import React,{ useState ,useContext,useEffect} from 'react'
import { Box,Card,Typography,IconButton, CardMedia,CardContent, Tooltip, LinearProgress} from "@material-ui/core";
import { ErrorGeneral, llamaApiCargaToken ,} from "../funciones/ClienteAxios";
import {PhoneAndroid,Info as InfoIcon,DoneAll as DoneAllIcon,PlayCircleFilled} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import qs from "qs";
import axios from "axios";
import ModalContactos from './ModalContactos'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {MuiPickersUtilsProvider,
        DatePicker,    
    } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';

const MensajeMasiva = () => {    
    let history = useHistory();
    const source = axios.CancelToken.source();
    let UsuId =localStorage.getItem('UsuId') ;       
    let Dispvo = localStorage.getItem("Dispvo");    
    let auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const {generales,guardarGenerales}=useContext(GeneralesContext);  
    const[listMsg,setListMsg]=useState([])
    const [espera, setEspera] = useState(false);
    const[modalinfo,setModalIinfo]= useState(false);
    const[seleccionado,setSeleccionado]= useState([]);
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
    const {inicio,final}=fSelect  
    useEffect(()=>{
        
        if (!authUser()) 
        {   localStorage.clear()
            history.push("/");      
        }
        else
        {   guardarGenerales({...generales,Titulo:'Alcance de Difusión'})  
            llamaApi();             
        }
       
       
    },[])

    const llamaApi = () => {      
        let fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        let fFinal=moment(fSelect.final).format("YYYY-MM-DD");  
        setListMsg([])
        let data = qs.stringify({
            Usuario: UsuId,              
            Instancia:1,
            FIni:fInicio+'T00:00:00',
            FFin:fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        let url = "difusion-chat-list-full-intervalo";
        function respuesta(auxiliar) {
          if (auxiliar[0].Id!==-1) 
          {                              
            setListMsg(auxiliar);
          }
        }
        llamaApiCargaToken( data, url, respuesta, ErrorGeneral, setEspera, history, source );
    };

    const guardarInicio = auxfecha =>
    {
      setFSelect({
        ...fSelect,
        inicio : auxfecha  
      })
    };

    const guardarFinal = auxfecha =>
    {
      setFSelect({
        ...fSelect,
        final : auxfecha  
      })
    };

    const Intervalo=()=>{
        return (
        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>    
            <Box  display={"flex"} >
              <Box   component="span" >                                                
                <DatePicker
                    disableFuture format="dd/MM/yyyy"
                    views={["year", "month", "date"]}
                    label="Fecha Inicial" size="small"
                    minDate="2021-12-31" maxDate={new Date()}
                    value={inicio} onChange={guardarInicio}      
                    style={{width:6.5+'rem'}}     
                    cancelLabel={"Cancelar"}                  
                    okLabel="Seleccionar"     
                />
              </Box>    
              <Box ml={1}  component="span"  >
                <DatePicker
                    disableFuture id="final"
                    format="dd/MM/yyyy" label="Fecha Final"
                    views={["year", "month", "date"]}
                    minDate={inicio} maxDate={new Date()}
                    value={final} name="final"
                    size="small" style={{width:6.5+'rem'}}      
                    cancelLabel={"Cancelar"}                  
                    okLabel="Seleccionar"    
                    onChange={guardarFinal}
                    minDateMessage={"La fecha final debe ser menor a Fecha inicial"}
                />
              </Box> 
              <Box pb={1}>
                <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={llamaApi}>
                    <Tooltip title="Buscar">
                    <SearchIcon/>
                    </Tooltip>
                </IconButton>            
                </Box>   
            </Box>                         
        </MuiPickersUtilsProvider>
        );
    }
    const seleccionar=(e,aux)=>{
        e.preventDefault()
        setSeleccionado(  aux)
        setModalIinfo(true)
    }

    const modal=modalinfo? <ModalContactos modalAbierto={modalinfo} setModalAbierto={setModalIinfo} 
                            list={seleccionado} />:null
  return (
    <Box >
        <Intervalo/>        
        <Box display={"flex"} width={"100%"} flexWrap="wrap" style={{marginTop:.5+'rem'}}>        
        {listMsg.length!==0 ?
        listMsg.map((aux,index)=>{
            return(
            <Card key={index} style={{ width:19+'rem',marginRight:.5+'rem',marginBottom:.8+'rem'}}>
                {aux.ArcTipo!=="video" ?
                    aux.Arch.length!==0? <CardMedia
                        component="img" alt="foto enviada"
                        height="140" image={aux.Arch?aux.Arch:""}
                        title="foto enviada"
                    />  :null
                : <Box display="flex" justifyContent={"center"} width={"100%"} >
                    <a href={aux.Arch?aux.Arch:""}>
                    <PlayCircleFilled style={{fontSize:"50px"}} />
                    </a>
                </Box>}
            <CardContent>
                <Typography  variant="body1" component="h3" color="primary" style={{fontWeight:600}} >
                    {moment(aux.FReg).format("DD/MM/YYYY HH:mm:ss")  }
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" textalign="center" justifyContent="center">
                    {` ${aux.Msg}`}
                </Typography>   
                <Box display={"flex"} mt={1}>
                    <IconButton onClick={e=>seleccionar(e,aux)} style={{marginLeft:1+'rem'}} size="small" disabled={aux.Contactos?false:true} >
                    <Tooltip title={`Contactos de Difusión`} >
                        <InfoIcon  color={aux.Contactos?"primary":"inherit" } />  
                    </Tooltip>
                    </IconButton>                    
                    <Box style={{marginLeft:.9+'rem',marginTop:.2+'rem'}} display="flex">
                        <Tooltip title={`Emitido a ${aux.EmisionTotal} teléfonos`} >
                            <PhoneAndroid color="primary"/>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {` ${aux.EmisionTotal}`}
                        </Typography>       
                    </Box>
                    <Box style={{marginLeft:.3+'rem',marginTop:.2+'rem'}} display="flex">
                        <Tooltip title={`Respondieron  ${aux.RespuestaTotal} teléfonos`} >
                        <DoneAllIcon style={{marginLeft:1+'rem',color:'green'}} />
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {` ${aux.RespuestaTotal}`}
                        </Typography> 
                    </Box>
                </Box>
                
            </CardContent>
                
            </Card>)
        })
        :espera?<Box width={"100%"}><LinearProgress/></Box>:<Typography>Sin Registros </Typography>}
        </Box>
    {modal}
    </Box>
  )
}

export default MensajeMasiva
import React,{useState} from 'react'
import {ErrorGeneral,llamadaApiExt} from '../funciones/ClienteAxios';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,    
        LinearProgress,TextField,FormLabel, Typography
    } from '@material-ui/core';
import { toast } from 'react-toastify';     

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '20ch',
        }, 
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        Textnumero: {
            width: 5+"px",
        },
        formControlFull: { width:5+"rem"
        },
        margin: {
            margin: theme.spacing(1),
        },
    },
    }));
function ListaTipo({listaTodo,setListaTodo}) {
    let history = useHistory();
    const classes = useStyles();
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    let UsuId =localStorage.getItem('UsuId') ;      
    let UsuTipo =localStorage.getItem('Tipo') ;          
    const source = axios.CancelToken.source();    
    const [mensaje, setMensaje] = useState('');
    const [aviso, setAviso] =  useState(false);    
    const [espera,setEspera]= useState(false);        
    const [listZona,setListZona]= useState([])
    const [listReg,setListReg]= useState([])
    const [listMun,setListMun]= useState([])
    const [listSecc,setListSecc]= useState([])
    const [listProm,setListProm]= useState([])
    const [zonaSelect,setZonaSelect]= useState("");
    const [regSelect,setRegSelect]= useState("");
    const [munSelect,setMunSelect]= useState("");
    const [seccSelect,setSeccSelect]= useState("");
    const [promSelect,setPromSelect]= useState("");
    
    React.useEffect(()=>{
        let bandera=true
        if(UsuTipo && bandera && parseInt(UsuTipo) === 1 )
        {   llamaDetList(UsuId,1,setListZona) 
            bandera=false
        } 
        else
        {   if( bandera && parseInt(UsuTipo) === 3 )
            {   llamaDetList(UsuId,3,setListReg) 
                bandera=false
            } 
            if( bandera && parseInt(UsuTipo) === 4)
            {   llamaDetList(UsuId,4,setListMun) 
                bandera=false
            } 
            if( bandera && parseInt(UsuTipo) === 5)
            {   llamaDetList(UsuId,5,setListSecc) 
                bandera=false
            } 
            if( bandera && parseInt(UsuTipo) === 6)
            {   llamaDetList(UsuId,6,setListProm) 
                bandera=false
            } 
        }

        return ()=> {
            source.cancel();
        }     
    },[])

    const llamaDetList = (UserId,nivel,setLista) => {             
        let data = qs.stringify({
            Usuario:UserId,     
            Tipo:nivel,       
            idDispositivo: auxDispV,
        });
        let url = "contacto-cel";
        function respuesta(auxiliar){                  
            
            if (auxiliar[0].Cel !== "" ) 
            {   auxiliar.splice(0,0,{leyenda:"TODO"})
                console.log(auxiliar);
               /*  let aux2=[]                
                auxiliar.forEach((element,index) => {                    
                    let aux={id:(contador+index+1),tel:element.Cel,valor:1}      
                    aux2.push(aux)                                            
                });   */   
                setLista(auxiliar)
                //setNivel(nivel)
            }              
        }
        llamadaApiExt(data, url, respuesta,ErrorGeneral,setEspera,history);
    };

    const onChangeZona=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setZonaSelect(e)             
            if (e.UsrId) 
            {   llamaDetList(e.UsrId,3,setListReg)   
                setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listZona)
            }            
        }
        else
        {   setZonaSelect([])                
        }        
        setListReg([])      
        setListMun([])  
        setListSecc([])
        setListProm([])
        setRegSelect([])      
        setMunSelect([])   
        setSeccSelect([]) 
        setPromSelect([])
    }

    const onChangeReg=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setRegSelect(e)             
            if (e.UsrId) 
            {   llamaDetList(e.UsrId,4,setListMun)                 
                setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listReg)
            }
        }
        else
        {   setRegSelect([])                
        }          
        setListMun([])  
        setListSecc([])
        setListProm([])             
        setMunSelect([])   
        setSeccSelect([])
        setPromSelect([])
    }

    const onChangeMun=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setMunSelect(e)             
            if (e.UsrId) 
            {   llamaDetList(e.UsrId,5,setListSecc)                 
                setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listMun)
            }             
        }
        else
        {   setMunSelect([])                
        }                  
        setListSecc([])
        setListProm([])
        setSeccSelect([])
        setPromSelect([])
    }

    const onChangeSecc=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setSeccSelect(e)                                          
            if (e.UsrId) 
            {   llamaDetList(e.UsrId,6,setListProm)                 
                setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listSecc)
            } 
        }
        else
        {   setSeccSelect([])                
        }  
        setListProm([])          
        setPromSelect([])                    
    }

    const onChangeProm=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setPromSelect(e)     
            if (e.UsrId) 
            {   setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listProm)
            }         
        }
        else
        {   setPromSelect([])                
        }                          
    }

    const opcionLablZon=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.ZonNom}`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablReg=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.RegNom}`    
        }
        else
        {   if (option.leyenda )
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablMun=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.MunNom}`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablSec=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.SecNom}`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablProm=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.ProNom}`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

  return (
    <Box>
        {listZona.length!==0 ?    
            <Autocomplete
                id="zona-list" size='small'  options={listZona} value={zonaSelect} name="zonaSelect"
                onChange={(e,option)=>onChangeZona(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablZon} style={{ width: 40+'rem' ,marginTop:.8+'rem'}}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Zona" variant="outlined" 
                    />
                )}
                renderOption={(option) => 
                    { let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.ZonNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>}
                }
            />    
        :null}
        {listReg.length!==0 ?  
           <Autocomplete
                id="reg-list" size='small'  options={listReg} value={regSelect} name="regSelect"
                onChange={(e,option)=>onChangeReg(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablReg} style={{ width: 40+'rem',marginTop:.8+'rem' }}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Region" variant="outlined" 
                    />
                )}
                renderOption={(option) => 
                    { let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.RegNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>}
                }
            />   
        :null}
        {listMun.length!==0 ?  
            listMun[0].Cargo!==""?
            <Autocomplete
                id="mun-list" size='small'  options={listMun} value={munSelect} name="munSelect"
                onChange={(e,option)=>onChangeMun(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablMun} style={{ width: 40+'rem',marginTop:.8+'rem'}}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Municipio" variant="outlined" 
                    />
                )}
                renderOption={(option) => { 
                    let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.MunNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>
                }}
            /> 
            :<Typography style={{marginTop:.5+'rem'}} > Sin municipios asignados</Typography>
        :null}
        {listSecc.length!==0 ?  
            listSecc[0].Cargo!==""?
            <Autocomplete
                id="secc-list" size='small'  options={listSecc} value={seccSelect} name="seccSelect"
                onChange={(e,option)=>onChangeSecc(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablSec} style={{ width: 40+'rem',marginTop:.8+'rem' }}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Sección" variant="outlined" 
                    />
                )}
                renderOption={(option) =>{ 
                    let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.SecNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>
                }}
            /> 
            :<Typography style={{marginTop:.5+'rem'}} > Sin secciones asignadas</Typography>
        :null}
        {listProm.length!==0 ?  
            listProm[0].Cargo!==""?
            <Autocomplete
                id="prom-list" size='small'  options={listProm} value={promSelect} name="promSelect"
                onChange={(e,option)=>onChangeProm(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablProm} style={{ width: 40+'rem' ,marginTop:.8+'rem'}}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Promotor" variant="outlined" 
                    />
                )}
                renderOption={(option) => { 
                    let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.ProNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>
                }}
            /> 
            :<Typography style={{marginTop:.5+'rem'}} > Sin promotores asignados</Typography>
        :null}
    </Box>
  )
}

export default ListaTipo
import React, {useState} from "react";
import {Card, Grid, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import Modal from "../generales/Modal";
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {authUser} from "../funciones/AuthUser";
import {ErrorGeneral, llamadaApiCarga} from "../funciones/ClienteAxios";
import qs from "qs";
import {warningToast} from '../generales/AlertasToast'

const EditarListaContactos = ({
                                  modalAbierto,
                                  setModalAbierto,
                                  titulo,
                                  DeviceId,
                                  history,
                                  UserId,
                                  difusionDetail,
                                  difusionListSelected,
                                  setDifusionDetail,actualizar
                              }) => {

    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

    const [contenido, setContenido] = useState({telefonos: ''})
    const {telefonos} = contenido
    const [listTel, setListTel] = useState([])
    const [contador, setContador] = useState(0)
    React.useEffect(()=>{
        console.log(difusionDetail);
        let nvo=[]
        if (difusionDetail[0].Id!==-1) {
            difusionDetail.forEach(element => {
               // console.log(element);
                nvo.push({...element,Status:1})
            });    
        }
        
        setListTel(nvo)
    },[difusionDetail])

    const updateContacts = () => {
        
        let url = "difusion-det-edit";
        let nvoArr=[]        
        if (!authUser()) {
            localStorage.clear()
            history.push("/");
        } 
        else 
        {
            //console.log(listTel,difusionDetail);            
            listTel.forEach(element => {
                if (difusionDetail[0].Id !== -1) 
                {
                    let filtrado=difusionDetail.filter((auxTel)=>auxTel.Cel===element.Cel)    
                    if (filtrado.length > 0  ) 
                    {   if ( filtrado[0].Status!==element.Status) 
                        { nvoArr.push({cel:element.Cel,status:element.Status})  
                        }                        
                    }
                    else
                    {
                        nvoArr.push({cel:element.Cel,status:element.Status})
                    }
                }
                else
                {
                    nvoArr.push({cel:element.Cel,status:element.Status})
                }
            });
            console.log(nvoArr);
            let data = qs.stringify({
                Usuario: UserId,
                Llave: difusionListSelected.Id,
                Contactos: JSON.stringify(nvoArr),
                idDispositivo: DeviceId,
            });
            function getDifusionList(response) {
                if (response) {
                    /* let dia=new Date()
                    let arrAgregar=difusionDetail
                    nvoArr.forEach(element => {
                        if (element.status===1) {
                         //   difusionDetail.push({Alias:element.cel,Cel:element.cel,Status:1,Fecha:dia})
                        }
                        else
                        {   
                            if (arrAgregar[0].Id !== -1) 
                            {
                                let pos = arrAgregar.findIndex(e => e.Cel === element.cel)
                      
                             //   difusionDetail.splice(pos, 1)    
                            }    
                        }
                    }); */

                    actualizar()
 
                    let mensaje = 'Lista de difusión editada correctamente.'
                    toast.success(mensaje, {
                        pauseOnFocusLoss: false, toastId: `nvo-perfil${6}`
                    })
                    setModalAbierto(false)
                } else {
                    let mensaje = response.mensaje;
                    toast.warning(mensaje, {
                        pauseOnFocusLoss: false, toastId: `nvo-perfil${6}`,
                    });
                }
            }
            llamadaApiCarga(data, url, getDifusionList, ErrorGeneral, setCargando, history);
        }


    }

   // console.log('ListTEl', listTel)

    const agregaTel = () => {
        let aux = []
        let aux2 = listTel
        let auxTelefonos = telefonos.trim()
        if (telefonos.length > 10) {
            console.log('Entre al primer if')
            let cantidad = (telefonos.match(/,/g) || []).length;
            let posicion = 0
            for (let index = 0; index < cantidad; index++) {
                let element = auxTelefonos.slice(0, auxTelefonos.indexOf(",")).trim()
                auxTelefonos = auxTelefonos.slice(auxTelefonos.indexOf(",") + 1, auxTelefonos.length)
                posicion = posicion + 1 + auxTelefonos.indexOf(",")
                let auxBusq = listTel.findIndex((telefono) => telefono.Cel === element)
                if (auxBusq === -1) {
                    let pos = listTel.findIndex(e => e.Cel === element)
                    if (pos !== -1) {
                        listTel[pos].Status = 1
                    } else {
                        console.log(element)
                        listTel.push({Cel: element, Status: 1})
                        setListTel([...listTel, {Cel: element, Status: 1}])
                    }
                    listTel.push({Cel: element, Status: 1})
                    setBotonDeshabilitado(false)
                } else {
                    toast.warning(`Teléfono ${element} ya seleccionado, escribe un número diferente`, {
                        pauseOnFocusLoss: false, theme: "colored", toastId: `incorrecto-sel${index + 2}`
                    });
                }
            }
            if (auxTelefonos.length === 10) {
                let auxBusq = listTel.findIndex((telefono) => telefono.Cel === auxTelefonos)
                if (auxBusq === -1) {
                    aux = {Cel: auxTelefonos.trim(), Status: 1}
                    aux2.push(aux)
                    auxTelefonos = ""
                    cantidad = cantidad + 1
                    setBotonDeshabilitado(false)
                } else {
                    toast.warning(`Teléfono ${auxTelefonos} ya seleccionado, escribe un número diferente`, {
                        pauseOnFocusLoss: false, theme: "colored", toastId: `incorrecto-sel${0}`
                    });
                }
            }
            setListTel(aux2)
            setContador(contador + cantidad)
            setContenido({...contenido, telefonos: ""})
        } else {
            let auxBusq = listTel.findIndex((telefono) => telefono.Cel === telefonos)
            if (auxBusq === -1) {
                let posicion = listTel.findIndex(element => element.Cel === telefonos)
                if (posicion !== -1) {
                    listTel[posicion].Status = 1
                } else {
                    setListTel([...listTel, {Cel: telefonos, Status: 1}])
                }
               // listTel([...listTel, {Cel: telefonos, Status: 1}])
                setContenido({...contenido, telefonos: ""})
                setBotonDeshabilitado(false)
            } else {
                warningToast(`Teléfono ${telefonos} ya seleccionado, escribe un número diferente`)
            }
        }
    }

    const agregaTel2 = () => {
        let aux = []
        let aux2 = listTel
        let auxTelefonos = telefonos.trim()
        if (telefonos.length > 10) {
            //console.log('Entre al primer if')
            let cantidad = (telefonos.match(/,/g) || []).length;
            let posicion = 0
            for (let index = 0; index < cantidad; index++) {
                let element = auxTelefonos.slice(0, auxTelefonos.indexOf(",")).trim()
                auxTelefonos = auxTelefonos.slice(auxTelefonos.indexOf(",") + 1, auxTelefonos.length)
                posicion = posicion + 1 + auxTelefonos.indexOf(",")
                if (element.length===10) 
                {   let pos = aux2.findIndex(e => e.Cel === element)
                    if (pos !== -1) 
                    {   
                        if (aux2[pos].Status===1) 
                        {   toast.warning(`Teléfono ${element} ya seleccionado, escribe un número diferente`, {
                                pauseOnFocusLoss: false, theme: "colored", toastId: `incorrecto-sel${index + 2}`
                            });
                        }
                        else
                        {   aux2.splice(pos, 1,{Cel: element, Status: 1})                    
                        }
                    } 
                    else 
                    {   //console.log(element)
                        aux2.push({Cel: element, Status: 1})                       
                    }                                  
                }
                else
                {   toast.warning(`El Teléfono ${element} no es correcto, escribe un número diferente`, {
                        pauseOnFocusLoss: false, theme: "colored", toastId: `incorrecto-sel${index + 2}`
                    });
                }                
            }
            if (auxTelefonos.trim().length === 10) 
            {
                let auxBusq = aux2.findIndex((telefono) => telefono.Cel === auxTelefonos)
                if (auxBusq === -1) 
                {
                    aux = {Cel: auxTelefonos.trim(), Status: 1}
                    aux2.push(aux)
                    auxTelefonos = ""
                    cantidad = cantidad + 1                    
                } 
                else 
                {   //console.log("saltando validacion01");
                    if (aux2[auxBusq].Status===1) 
                    {   toast.warning(`Teléfono ${auxTelefonos} ya seleccionado, escribe un número diferente`, {
                            pauseOnFocusLoss: false, theme: "colored", toastId: `incorrecto-sel${0}`
                        });
                    }
                    else
                    {   aux2.splice(auxBusq, 1,{Cel: auxTelefonos.trim(), Status: 1})                    
                    }                    
                }
            }
            setBotonDeshabilitado(false)
            setListTel(aux2)
            setContador(contador + cantidad)
            setContenido({...contenido, telefonos: ""})
        } 
        else 
        {            
            if (parseInt(auxTelefonos) && auxTelefonos.length===10) 
            {   let auxArr=listTel
                let posicion = listTel.findIndex(element => parseInt(element.Cel) === parseInt(auxTelefonos))
                if (posicion !== -1) 
                {   
                    if (auxArr[posicion].Status===1) 
                    {   warningToast(`Teléfono ${auxTelefonos} ya seleccionado, escribe un número diferente`)
                    }
                    else
                    {   auxArr.splice(posicion, 1,{Cel: auxTelefonos, Status: 1})                    
                    }
                } 
                else 
                {   auxArr.push({Cel: auxTelefonos, Status: 1})                    
                }
                //console.log(auxArr);
                setListTel(auxArr)               
                setContenido({...contenido, telefonos: ""})
                setBotonDeshabilitado(false)                
            } 
            else 
            {   warningToast(`Escribe un número correcto`)
            }
        }
    }


    function borrarTel(numero) {
        //let arrAux = listTel.filter(element => element.Cel !== numero)
        //setDifusionDetail(arrAux)
        let aux=[]
        let posicion = listTel.findIndex(element => parseInt(element.Cel) === parseInt(numero))
        console.log(posicion);
        if (posicion !== -1) {
            //aux[posicion].Status = 0
            listTel.forEach((element,index) => {
                if (index===posicion) 
                {
                    aux.push({...element, Status: 0})
                }else{
                    aux.push(element)
                }
            });
            setListTel(aux)
        } 
        setBotonDeshabilitado(false)
    }

    const onChangeTel = e => {
        let expNopermitida = new RegExp('[A-Za-z.$@#_&-+()/:;!?<>=%{}*|`]');
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');
        let expCadena = new RegExp('[0-9,]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) || e.target.value === ' ' || e.target.value === '') {
            setContenido({
                ...contenido, [e.target.name]: e.target.value
            })
            if (aviso) {
                setMensaje('');
                setAviso(false);
            }
        }
    };

    return (<Modal
        hideBackdrop
        disableEscapeKeyDown
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={updateContacts}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        setAviso={setAviso}
        tamanio={"sm"}
        btnDeshabilitado={botonDeshabilitado}
        cargando={cargando}
        subtitulo={""}
    >
        <Grid container alignItems={'center'} justifyContent={'center'} style={{textAlign: 'center'}}>
            <Grid item xs={12} style={{marginBottom: 10}}>
                {listTel.length > 0 ?
                    <Typography color='primary' variant={'h5'}>Teléfonos Seleccionados</Typography> : null}
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} justifyContent={'center'}>
                    {listTel.length > 0 ? 
                        listTel.map((element, index) => (
                        element.Status === 1 ?
                            <Grid item style={{marginBottom: 15, marginRight: 5}}>
                                <Card key={index}>
                                    <Grid container style={{padding: '.3rem'}}>
                                        <Grid item>
                                            <Typography color={"primary"}> {element.Cel}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={'Borrar de la lista'}>
                                                <IconButton onClick={() => borrarTel(element.Cel)}
                                                            style={{padding: 0, marginLeft: 5}}>
                                                    <HighlightOffIcon fontSize="small" color={'primary'}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid> : null
                    )) : null}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <TextField
                            variant="outlined" margin="none" required
                            label="Teléfono(s)" name="telefonos" type="text" value={telefonos}
                            onChange={onChangeTel} autoFocus size={'small'}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title={`Seleccionar Teléfono`}>
                           <span>
                                <IconButton disabled={telefonos.length < 10 || telefonos.length === 20 ? true : false}
                                            color="primary"
                                            onClick={agregaTel2}
                                >
                                <AddIcCallIcon/>
                            </IconButton>
                           </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Modal>);
};
export default EditarListaContactos;

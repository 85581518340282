import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState,useContext } from "react";
import { Grid } from "@material-ui/core";
import Sidebar from "./Sidebar";
import { authUser } from '../funciones/AuthUser';
import { useHistory } from "react-router-dom";
import Chat from "./Chat";
//import socket from "../socket/Socket";
import {GeneralesContext} from '../context/GeneralesContext'
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*": {
      margin: 0,
    },
    body: {
      margin: 0,
    },
  },
  container: {
    backgroundColor: "#F2F2F2",
    color:'black !important'
    //width: "100%",
    //boxShadow: "-1px 4px 20px 6px rgb(0,0,0, 0.2)",
  },
  app: {
    display: "grid",
    placeItems: "center",
    backgroundColor: "#dadbd3",
    height: "70vh",
  },
  appBody: {
    display: "flex",
    backgroundColor: "#ededed",
    height: "70vh",
    width: "100%",
    //boxShadow: "-1px 4px 20px -6px rgba(0, 0, 0, 0.75)",
  },
}));

const ChatIndex = () => {
  const classes = useStyles();
  const {generales,guardarGenerales}=useContext(GeneralesContext);   
  const [user, setUser] = useState(null);
  let history = useHistory();
  const [showSidebar, setShowSidebar] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [numChat, setNumChat] = useState([]);
  const [viewPort, setViewPort] = useState(window.innerWidth);

  useEffect(() => {
    if (!authUser()) {
      localStorage.clear()
      history.push("/");      
    }
    else{  
      guardarGenerales({...generales,Titulo:'Respuestas de Difusión'})  
      setViewPort(window.innerWidth);
      if (viewPort <= 768) {
        setShowChat(false);
      }
    }
  }, [setShowSidebar, setShowChat]);

  //socket.emit('conectado', 'Hola desde el cliente...')

  return (
    <div className={classes.app} id="contenedor-chat">
      {false ?
       (
        <h1>Muestra Login</h1>
      ) : (
        <div className={classes.appBody} id="cuerpo-chat">
          <Grid container className={classes.container} id="cuerpo-chat2">
            {showSidebar ? (
              <Grid item xs={12} sm={12} md={3}>
                <Sidebar
                  setShowChat={setShowChat}
                  setShowSidebar={setShowSidebar}
                  viewPort={viewPort}
                  setNumChat={setNumChat}
                />
              </Grid>
            ) : null}
            {showChat && numChat.length!==0? (
              <Grid item xs={12} sm={12} md={9}>
                <Chat
                    setShowChat={setShowChat}
                    showSidebar={showSidebar}
                    numChat={numChat}
                    setShowSidebar={setShowSidebar}
                  />                
              </Grid>
            ) : null}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ChatIndex;

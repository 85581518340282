import {toast} from "react-toastify";

export function successToast(mensaje){

}

export function warningToast(mensaje){
    toast.warning(mensaje, {
        pauseOnFocusLoss: false, theme: "colored", toastId: `incorrecto-sel${1}`
    });
}
import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, TextField} from "@material-ui/core";
import Modal from "../generales/Modal";
import qs from "qs";
import {ErrorGeneral, llamadaApiCarga} from '../funciones/ClienteAxios';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
    root: {
        margin: {
            margin: 16,
        }
    }
}));

const CrearListaDifusion = ({
                                modalAbierto,
                                setModalAbierto,
                                titulo,
                                difusionList,
                                setDifusionList,
                                UserId,
                                DeviceId,
                                history,
                            }) => {

    const classes = useStyles();

    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [error, setError] = useState(false);
    const [nuevoRegistro, setNuevoRegistro] = useState({Id: '', Nom: '', Status: ''});

    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

    console.log('difusionList', difusionList)

    const crearListaDifusion = () => {
        setCargando(true)
        let data = qs.stringify({
            Usuario: UserId, Nombre: nuevoRegistro.Nom, idDispositivo: DeviceId,
        });

        let url = "/difusion-nuevo";

        function response(response) {
            if (response) {
                let mensaje = 'Lista de difusión agregada correctamente.'
                toast.success(mensaje, {
                    pauseOnFocusLoss: false, toastId: `nvo-perfil${6}`
                })
                setDifusionList([...difusionList, {
                    Id: response.mensaje, Fecha: Date.now(), Nom: nuevoRegistro.Nom, Status: 1
                }])
                cerrarBorrar()
            } else {
                let mensaje = response.mensaje
                toast.warning(mensaje, {
                    pauseOnFocusLoss: false, toastId: `nvo-perfil${6}`
                })
            }
            setCargando(false)
        }

        llamadaApiCarga(data, url, response, ErrorGeneral, setCargando, history);

    }

    const cerrarBorrar = () => {
        setAviso(false);
        setModalAbierto(false)
    };

    const handleNuevoRegistro = (event) => {
        setNuevoRegistro({...nuevoRegistro, Nom: event.target.value})
        let dato = (difusionList.find(element => element.Nom === event.target.value))
        if (dato) {
            setError(true)
            setBotonDeshabilitado(true)
        } else {
            setError(false)
            setBotonDeshabilitado(false)
        }
    }

    const validar = () => {
        if (nuevoRegistro.Nom !== '') {
            setCargando(true)
            return crearListaDifusion()
        } else {
            toast.error('Error al crear nueva lista de difusión.', {
                pauseOnFocusLoss: false, toastId: `nvo-perfil${6}`
            })
        }
    }

    return (<Modal hideBackdrop
                   disableEscapeKeyDown
                   titulo={titulo}
                   modalAbierto={modalAbierto}
                   setModalAbierto={setModalAbierto}
                   guardarDatos={validar}
                   mensaje={mensaje}
                   tipoAdvertencia={tipoAdvertencia}
                   aviso={aviso}
                   setAviso={setAviso}
                   tamanio={"sm"}
                   btnDeshabilitado={botonDeshabilitado}
                   cargando={cargando}
                   subtitulo={''}
    >
        <form className={classes.root} autoComplete="off">
            <Grid container justifyContent={'space-around'} spacing={2}>
                <Grid item xs={12}>
                    {error ? <Alert severity="error">El nombre de lista de difusión ya existe.</Alert> : null}
                    <TextField id="new-difusion" label="Nombre"
                               onChange={handleNuevoRegistro}/>
                </Grid>
            </Grid>
        </form>
    </Modal>);
};

export default CrearListaDifusion;
import { Button } from '@material-ui/core'
import React,{useEffect,useState} from 'react'

const Prueba = () => {
    const[arreglo,setArreglo]=useState([])
    useEffect(() => {
        let base=3000
        let nvoArr=[]
        for (let i = 0; i < 2500; i++) {
            nvoArr.push({cel:base +i});            
        }
        setArreglo(nvoArr)
    }, [])
    
    function mensajes() {
        let veces = arreglo.length / 300   
        let resto = arreglo.length % 300   
        console.log(veces,Math.trunc(veces),`x 300 = ${Math.trunc(veces)*300} + ${resto}`);
        //setDatos(lista.slice(0,tamMax))
    }

  return (
    <div>
        <Button onClick={()=>mensajes()}>
            Prueba
        </Button>        
    </div>
  )
}

export default Prueba
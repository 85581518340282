import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, TextField} from "@material-ui/core";
import Modal from "../generales/Modal";
import qs from "qs";
import {ErrorGeneral, llamadaApiCarga} from "../funciones/ClienteAxios";
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
    },
}));

const EditarContacto = ({
                            modalAbierto,
                            setModalAbierto,
                            titulo,
                            DeviceId,
                            difusionSelected,
                            UserId,
                            history,
                            difusionDetail
                        }) => {

    const classes = useStyles();

    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [contacto, setContacto] = useState({
        Alias: difusionSelected.Alias,
        Cel: difusionSelected.Cel,
        Fecha: difusionSelected.Fecha,
        Id: difusionSelected.Id,
        Status: difusionSelected.Status,
    });
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

    console.log(difusionSelected)

    const editarListaDifusion = () => {
        setCargando(true);
        let data = qs.stringify({
            Usuario: UserId,
            Cel: contacto.Cel,
            Alias: contacto.Alias,
            idDispositivo: DeviceId,
        });

        let url = "/contacto-edit";

        function response(response) {
            if (response) {
                let mensaje = "Contacto editado correctamente.";
                toast.success(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-perfil${6}`,
                });
                let posicion = difusionDetail.findIndex((element) => element.Id === contacto.Id);
                difusionDetail.splice(posicion, 1, contacto);
                setModalAbierto(false)
            } else {
                let mensaje = response.mensaje;
                toast.warning(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-perfil${6}`,
                });
            }
            setCargando(false);
        }

        llamadaApiCarga(data, url, response, ErrorGeneral, setCargando, history);
    };

    const handleAlias = (e) => {
        setContacto({...contacto, Alias: e.target.value});
        setBotonDeshabilitado(false);
    };

    return (
        <Modal
            hideBackdrop
            disableEscapeKeyDown
            titulo={titulo}
            modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto}
            guardarDatos={editarListaDifusion}
            mensaje={mensaje}
            tipoAdvertencia={tipoAdvertencia}
            aviso={aviso}
            setAviso={setAviso}
            tamanio={"sm"}
            btnDeshabilitado={botonDeshabilitado}
            cargando={cargando}
            subtitulo={""}
        >
            <form className={classes.root} autoComplete="off">
                <Grid container justifyContent={"space-around"} spacing={2}>
                    <Grid item>
                        <TextField
                            label={"Alias"}
                            defaultValue={difusionSelected.Alias}
                            onChange={handleAlias}
                        ></TextField>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};
export default EditarContacto;

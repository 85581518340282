import {FormControl, Grid, IconButton, LinearProgress, Paper, Tooltip, Typography,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {toast} from "react-toastify";
import {ErrorGeneral, llamadaApiCarga} from "../funciones/ClienteAxios";
import {useEffect, useState,useContext} from "react";
import qs from "qs";
import {useHistory} from "react-router-dom";
import TablaDifusion from "./TablaDifusion";
import TablaListaDifusion from "./TablaListaDifusion";
import CrearListaDifusion from "./CrearListaDifusion";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {authUser} from '../funciones/AuthUser';
import {GeneralesContext} from '../context/GeneralesContext'
const useStyles = makeStyles({
    root: {
        width: '30vw',
    }
});

const DifusionIndex = () => {
    const classes = useStyles();
    let history = useHistory();
    let UserId = localStorage.getItem("UsuId");
    let Device = localStorage.getItem("Dispvo");
    let DeviceId = Device.length >= 12 ? Device : "451224452121";
    const {generales,guardarGenerales}=useContext(GeneralesContext);   
    const [espera, setEspera] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [modalAgregarAbierto, setModalAgregarAbierto] = useState(false);

    const [titulo, setModalTitulo] = useState("");

    const [difusionDetail, setDifusionDetail] = useState([]);
    const [selectedList, setSelectedList] = useState(-1);
    const [difusionList, setDifusionList] = useState([]);
    const [actualiza, setActualiza] = useState(false);
    
    const [ordenBusq, setOrdenBusq] = useState({solucionId: ""});
    const {solucionId} = ordenBusq;

    useEffect(() => {
        let data = qs.stringify({
            Usuario: UserId,
            Status: 0,
            idDispositivo: DeviceId,
        });
        let url = "/difusion-list";

        function getDifusionList(response) {
            if (response) {
                setDifusionList(response);
            } else {
                let mensaje = response.mensaje;
                toast.warning(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-perfil${6}`,
                });
            }
        }

        if (!authUser()) {
            localStorage.clear()
            history.push("/");
        } else {
            guardarGenerales({...generales,Titulo:'Catálogo Lista de Difusión'})  
            llamadaApiCarga(
                data,
                url,
                getDifusionList,
                ErrorGeneral,
                setEspera,
                history
            );
        }
    }, []);

    useEffect(() => {
        setCargando(true)
        let data = qs.stringify({
            Usuario: UserId,
            Llave: selectedList,
            idDispositivo: DeviceId,
        });
        let url = "/difusion-det-list";

        function getDifusionDetails(response) {
            if (response) {
                setDifusionDetail(response);
                setCargando(false)
            } else {
                let mensaje = response.mensaje;
                toast.warning(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-perfil${6}`,
                });
            }
        }

        if (selectedList !==-1) {
            llamadaApiCarga(
                data,
                url,
                getDifusionDetails,
                ErrorGeneral,
                setEspera,
                history
            );        
        }
        
    }, [selectedList,actualiza]);

    const actualizar=()=>{
        setActualiza(!actualiza)
    }

    const modalAgregar = modalAgregarAbierto ? (
        <CrearListaDifusion
            modalAbierto={modalAgregarAbierto}
            setModalAbierto={setModalAgregarAbierto}
            titulo={titulo}
            setDifusionList={setDifusionList}
            difusionList={difusionList}
            UserId={UserId}
            DeviceId={DeviceId}
            history={history}
        />
    ) : null;

    function abrirModal() {
        setModalTitulo("Crear nueva lista de difusión");
        setModalAgregarAbierto(!modalAgregarAbierto);
    }

    const botonAgregar = (
        <FormControl>
            <Tooltip title={'Agregar nueva lista de difusion'}>
                <IconButton onClick={abrirModal} size={"small"}>
                    <AddCircleOutlineIcon style={{color: "white"}} size={"small"}/>
                </IconButton>
            </Tooltip>
        </FormControl>
    );

    return (
        <Grid container>
            <Grid item>
                <TablaListaDifusion
                    difusionList={difusionList}
                    setDifusionDetail={setDifusionDetail}
                    difusionDetail={difusionDetail}
                    UserId={UserId}
                    DeviceId={DeviceId}
                    history={history} botonAgregar={botonAgregar}
                    setSelectedList={setSelectedList}
                    actualizar={actualizar}
                />
            </Grid>
            <Grid item style={{marginLeft: '0.625rem'}}>
                {difusionDetail.length > 0 ?
                    cargando ?
                        <Paper className={classes.root}>
                            <Typography>
                                Cargando...
                                <LinearProgress/>
                            </Typography>
                        </Paper>
                        :
                        <TablaDifusion
                            difusionDetail={difusionDetail}
                            solucionId={solucionId}
                            UserId={UserId}
                            DeviceId={DeviceId}
                            history={history}
                        /> : null
                }
            </Grid>
            {modalAgregar}
        </Grid>
    );
};

export default DifusionIndex;

import {Dialog, DialogContent,} from "@material-ui/core";

const DialogImage = ({
                         children,
                         openImage,
                         setOpenImage,
                         index
                     }) => {

    const handleClose = () => {
        setOpenImage(false);
    };

    return (
        <Dialog
            open={openImage}
            onClose={handleClose}
            aria-labelledby={index}
            aria-describedby={index}
        >
            <DialogContent style={{padding: 0}}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default DialogImage;


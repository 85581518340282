import React from 'react'
import {ErrorGeneral,llamadaApiCarga} from '../funciones/ClienteAxios';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,    
        LinearProgress,TextField,FormLabel, Typography
    } from '@material-ui/core';
import { toast } from 'react-toastify';     

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '20ch',
        }, 
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        Textnumero: {
            width: 5+"px",
        },
        formControlFull: { width:5+"rem"
        },
        margin: {
            margin: theme.spacing(1),
        },
    },
    }));
function Lista({setListTel,listTel,setContador,contador}) {
    let history = useHistory();
    const classes = useStyles();
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    let UsuId =localStorage.getItem('UsuId') ;          
    const source = axios.CancelToken.source();    
    const [mensaje, setMensaje] = React.useState('');
    const [aviso, setAviso] = React.useState(false);
    const [lista,setLista]=React.useState([])
    const [espera,setEspera]=React.useState(false);
    const [listaSelect,setListaSelect]=React.useState("");
    const [listaDet,setListaDet]=React.useState([]);
    React.useEffect(()=>{
        llamaApi()
        return ()=> {
            source.cancel();
        }     
    },[])

    const llamaApi = () => {                 
        let data = qs.stringify({
            Usuario:UsuId,       
            Status:1,     
            idDispositivo: auxDispV,
        });
        let url = "difusion-list";
        function respuesta(auxiliar){                  
            if (auxiliar[0].Id !== -1 ) 
            {   console.log(auxiliar);
                setLista(auxiliar)
            }           
        }
        llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
    };
    
    const llamaDetList = (llave) => {             
        let data = qs.stringify({
            Usuario:UsuId,     
            Llave:llave,       
            idDispositivo: auxDispV,
        });
        let url = "difusion-det-list";
        function respuesta(auxiliar){                  
            if (auxiliar[0].Id !== -1 ) 
            {   console.log(auxiliar);
                let aux2=listTel
                setListaDet(auxiliar)
                auxiliar.forEach((element,index) => {
                    let auxBusq=aux2.findIndex((telefono)=>telefono.tel===element.Cel)
                    if (auxBusq === -1 ) {
                        let aux={id:(contador+index+1),tel:element.Cel,valor:1}      
                        aux2.push(aux)                        
                        //setContenido({...contenido,telefonos:""})
                    }
                    else{
                        let auxmensaje=`Teléfono ${element.Cel} ya seleccionado, escribe un número diferente`
                        toast.warning(auxmensaje ,{
                        pauseOnFocusLoss: false, theme: "colored",
                        toastId: `list--tel-error${index}`
                        }); 
                    }       
                });     
                setListTel(aux2)                                   
                setContador(contador+1+auxiliar.length)
            }   
            else{
                console.log("sin contenido");
                toast.warning("Lista sin contactos",{
                    pauseOnFocusLoss: false,                    
                    toastId: `list-dif${llave}`
                }) 
            }        
        }
        llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
    };

    const seleccionarList = e =>
    { 
        setListaSelect( e.target.value )
        llamaDetList(e.target.value) 
        if (aviso) 
        { setMensaje('');
            setAviso(false);
        }
    };

  return (
    <Box>
        {lista.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:15+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Lista Difusión</InputLabel>
              <MaterialSelect
                native value={listaSelect} onChange={seleccionarList}
                inputProps={{ name: 'listaSelect', id: 'listaSelect'}}
              >
                <option aria-label="None" value="" />      
                {lista.map((row,index) => (
                    <option key={index} value={row.Id}>{row.Nom}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
        :null}
    </Box>
  )
}

export default Lista